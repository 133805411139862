
import { ShopItemType,ShopOrderSucType } from "@/Type/index"
import { AllUpDataType,MyOrderListType } from "@/Api/Shop/interfaceType"
import store from "../../index"
import { VuexModule,getModule,Module,Mutation } from "vuex-module-decorators"

interface ScrollType<T=number>{ // 滚动条值
    SnackScroll:T; // 零食广场 
    EatScroll:T; // 外卖
    ShopScroll:T; // 百货
    FreshScroll:T; // 新鲜水果
    MySnackScroll:T; // 我的零食订单记录
    MyEatScroll:T; // 我的外卖订单记录
    MyShopScroll:T; // 我的百货订单记录
    MyFreshScroll:T; // 我的新鲜水果订单记录
}

interface MyListType<T=string>{ // 
    MySnackList:Array<ShopOrderSucType>;
    MyEatList:Array<ShopOrderSucType>;
    MyShopList:Array<ShopOrderSucType>;
    MyFreshList:Array<ShopOrderSucType>
}

interface MyUpDataType<T=string>{
    MySnackUpData:MyOrderListType;
    MyEatUpData:MyOrderListType;
    MyShopUpData:MyOrderListType;
    MyFreshUpData:MyOrderListType
}

export interface ShopType<T=string>{
    AllShopList:ShopItemType[] // 广场列表
    ShopScroll:ScrollType; // 零食 外卖 百货 滚动条值
    ShopUpData:AllUpDataType; // 广场请求类型
    MyUpData:MyUpDataType; // 我的订单请求类型
    MyShopList:MyListType; // 我的订单列表

    SetScrollType(data:ScrollType):void;
    SetAllShopList(data:any[]):void;
    SetShopUpData(data:AllUpDataType):void;
    SetMyUpData(data:MyUpDataType):void;
    SetMyShopList(data:MyListType):void;
}
//dynamic
@Module({
    name:"Shop",
    store,
    dynamic:true
})
export default class Shop extends VuexModule implements ShopType{
    ShopScroll: ScrollType<number> = {
        SnackScroll:0,
        EatScroll:0,
        ShopScroll:0,
        FreshScroll:0,
        MySnackScroll:0,
        MyEatScroll:0,
        MyShopScroll:0,
        MyFreshScroll:0
    }
    AllShopList: ShopItemType[] = []
    ShopUpData:AllUpDataType = {
        pageNo:1,
        pageSize:15,
        type:2
    }
    MyUpData:MyUpDataType = {
        MySnackUpData:{
            pageNo:1,
            pageSize:15,
            storeType:2
        },
        MyEatUpData:{
            pageNo:1,
            pageSize:15,
            storeType:0
        },
        MyShopUpData:{
            pageNo:1,
            pageSize:15,
            storeType:1
        },
        MyFreshUpData:{
            pageNo:1,
            pageSize:15,
            storeType:3
        }
    }
    MyShopList:MyListType = {
        MySnackList:[],
        MyEatList:[],
        MyShopList:[],
        MyFreshList:[]
    }

    //滚动值
    get GetShopScroll(){
        return this.ShopScroll
    }
    @Mutation
    SetScrollType(data: ScrollType<number>): void {
        this.ShopScroll = data
    }
    // 外卖广场
    get GetAllShopList(){
        return this.AllShopList
    }
    @Mutation
    SetAllShopList(data: ShopItemType[]): void {
        this.AllShopList = data
    }
    // 广场 请求参数
    get GetShopUpData(){
        return this.ShopUpData
    }
    @Mutation
    SetShopUpData(data:AllUpDataType){
        this.ShopUpData = data
    }
    // 我的请求类型
    get GetMyUpData(){
        return this.MyUpData
    }
    @Mutation
    SetMyUpData(data:MyUpDataType){
        this.MyUpData = data
    }
    // 我的订单列表
    get GetMyShopList(){
        return this.MyShopList
    }
    @Mutation
    SetMyShopList(data:MyListType){
        this.MyShopList = data
    }
}

export const ShopStore = getModule(Shop)